import React from 'react'
import BrandBlock from './BrandBlock'
import LegalBlock from './LegalBlock'

const Footer = () => {
  return (
    <footer className="footer-alz">
        <div className="container">
          <div className="row">
            <BrandBlock />
          </div>
          <LegalBlock />
        </div>
    </footer>
  )
}

export default Footer