import urlBase from '../../../../shared/infrastructure/api/backend-urls';

export const urlGetFixturesResults = (league_id = ':league_id' ) => `https://apiv3.apifootball.com/?action=get_events&from=2022-01-01&to=2022-12-31&league_id=${league_id}&timezone=America/Bogota&APIkey=${process.env.REACT_APP_API_KEY_APIFOOTBALL}`;

export const urlGetFixturesStandings = (league_id = ':league_id' ) => `https://apiv3.apifootball.com/?action=get_standings&league_id=${league_id}&APIkey=${process.env.REACT_APP_API_KEY_APIFOOTBALL}`;

export const urlGetFixturesLive = (league_id = ':league_id' ) => `https://apiv3.apifootball.com/?action=get_events&league_id=${league_id}&match_live=1&APIkey=${process.env.REACT_APP_API_KEY_APIFOOTBALL}`;

// 28 = Qatar
// 120 = Colombia Liga
