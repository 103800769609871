import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { homeRoute } from '../../../../domains/home/infrastructure/routes'
// import { leagueRoute } from '../../../../domains/League/infrastructure/routes'
import SubMenu from './SubMenu'

const Header = () => {
    const [showSubNav, setShowSubNav] = useState(false);
    const [showEvent, setShowEvent] = useState('');
    const [showLineMenuL, setShowLineMenuL] = useState(false);
    const [showLineMenuC, setShowLineMenuC] = useState(false);

    const handleSubNavClick = (e) => {
        e.preventDefault();

        setShowEvent(e.target.dataset.event);
        setShowSubNav(true);

        if (e.target.dataset.event === 'leagues') {
            setShowLineMenuL(true)
            setShowLineMenuC(false)
        }

        if (e.target.dataset.event === 'competitions') {
            setShowLineMenuC(true)
            setShowLineMenuL(false)
        }
    };

    const handleSubNavLeaveClick = (e) => {
        setShowSubNav(false)
        setShowLineMenuL(false)
        setShowLineMenuC(false)
    }

  return (
    <header className="header" onMouseLeave={handleSubNavLeaveClick}>
        <div className="main-menu">
            <div className="content">
                <ul>
                    <li onMouseEnter={handleSubNavLeaveClick}><Link to={homeRoute} className="nav-link">Inicio</Link></li>
                    <li onMouseEnter={handleSubNavLeaveClick}><Link to={'#'} className="nav-link">Noticias</Link></li>
                    <li onMouseEnter={handleSubNavLeaveClick} className="main-logo">
                        <Link to={homeRoute} className="nav-link">
                            <div className="content-logo">
                                <img src="/logo_alz_sports.png" alt='' />
                            </div>
                        </Link>
                    </li>
                    <li className={`${showLineMenuL && 'active'}`}><a href='/#' className="nav-link" data-event={'leagues'} onMouseEnter={handleSubNavClick}>Ligas</a></li>
                    <li className={`${showLineMenuC && 'active'}`}><a href='/#' className="nav-link" data-event={'competitions'} onMouseEnter={handleSubNavClick}>Competencias</a></li>
                </ul>

            </div>
        </div>
        {/* {showSubNav && */}
            <SubMenu show={showSubNav} event={showEvent} onMouseEnter={handleSubNavClick} />
        {/* } */}
    </header>
  )
}

export default Header