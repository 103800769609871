import { HelmetProvider } from 'react-helmet-async';
import Router from './shared/presentation/Router';
// import './App.css';

const App = () => {
  return (
    <HelmetProvider>
      <Router />
    </HelmetProvider>
  );
}

export default App;
