// import { AdminLayout, PrivateRoute } from '@vecindario/suite-dashboard-layout-lib';
import { Route } from 'react-router-dom';
import AdminLayout from '../../../shared/presentation/layouts/AdminLayout';
import League from '../presentation/pages/League';
import { leagueRoute } from './routes';

const leaguesRouter = {
	layout: AdminLayout,
	children: [
		{
			path: leagueRoute(),
			// path: leagueRoute,
			element: <League />,
			routeComponent: Route,
		}
	]
};

export default leaguesRouter;
