import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getValueFromLocalStorage } from '../../../../../shared/application/helpers/common-functions';
import './InfoLeagues.scss';


const InfoLeagues = () => {
    const location = useLocation()
    const league_id = location.pathname.split("-", -1).slice(-1);
    const [leagueID, setLeagueID] = useState(league_id[0]);
    const [infoLeagues, setInfoLeagues] = useState([]);

    useEffect(() => {
        setLeagueID(league_id[0]);
    },[league_id])

    useEffect(() => {
        setInfoLeagues(JSON.parse(getValueFromLocalStorage('leagues')))
    }, []);

    console.log('infoLeagues', infoLeagues);

	return (
        infoLeagues.length !== 0 && (
            <section className="info-league-section">
                <div className="container" style={{maxWidth: "1288px"}}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="info-league">
                                {infoLeagues.filter(info => info.league_id === leagueID).map((item) => (
                                    <div key={item.league_id} className="content-info">
                                        <div className="logo-league">
                                            <img className="logo" src={item.league_logo} alt={item.league_name} />
                                        </div>
                                        <div className="data-league">
                                            <h1 className="title">{item.league_name === "Primera A" ? 'Liga BetPlay' : item.league_name}</h1>
                                            <span>{item.league_name} {item.country_name === 'Colombia' ? `/ Clausura ${item.league_season}` : item.league_season} - {item.country_name}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
	);
};

export default InfoLeagues;