import React, {useEffect} from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminLayout from '../presentation/layouts/AdminLayout';
import homeRouter from '../../domains/home/infrastructure/router';
import leaguesRouter from '../../domains/League/infrastructure/router';
import competitionRouter from '../../domains/competition/infrastructure/router';
import postRouter from '../../domains/blog/infrastructure/router';
// import { homeRoute } from '../../domains/home/infrastructure/routes';
import { getLeagues } from '../infrastructure/api/apiHandler';
import { setValueToLocalStorage } from '../application/helpers/common-functions';

const Router = () => {
	const defaultLayout = ({ children }) => <>{children}</>;
	const routes = [homeRouter, leaguesRouter, competitionRouter, postRouter];

	useEffect(() => {
        getLeagues().then((resp) => {
            try {
				setValueToLocalStorage('leagues', JSON.stringify(resp));
            } catch (error) {
                console.log(error);
            }
        });
    }, []);
	
	return (
		<Routes forceRefresh={true}>
			{routes.map((route) => {
					return route?.children?.map(({ path, element, routeComponent: Route, exact = true, layout, ...rest }) => (
						
							<Route
								key={path}
								exact={exact}
								path={path}
								element={<AdminLayout>{element}</AdminLayout>}
								layout={layout || route.layout || defaultLayout}
								{...rest}
							/>
						
					))
			})}
			<Route 
				path="*" 
				element={
					<div className="text-center my-5 py-5">
						<h1>Error 404</h1>
						<p>La página que buscas no existe</p>
					</div>
				} 
			/>
		{/* <Route path="*">
			<AdminLayout>
				<Error404 backRoute={homeRoute} />
			</AdminLayout>
		</Route> */}
		</Routes>
	);
};

export default Router;
