import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Moment from 'moment';
import 'moment/locale/es';
// import BoardBody from '../components/BoardBody';
// import BoardHeader from '../components/BoardHeader';
import { getBlogHubSpot } from '../../../infrastructure/api';
import './BlogHome.scss';

const BlogHome = () => {
	const [blog, setBlog] = useState([]);
	

	useEffect(() => {
		getBlogHubSpot()
			.then((response) => {
				console.log('response', response);
				const blogPosts = response.results.sort((b, a) => {
					const c = new Date(a.publishDate);
    				const d = new Date(b.publishDate);
					return c-d;
				});
				console.log('blogPosts', blogPosts);
				setBlog(response.results);
			})
	},[])

	return (
		<>
			{/* <Helmet title={'Vecindario Suite - Oportunidades de negocio - Tablero'} /> */}
			<section className="blog-home-section">
				<div className="header-section">
					<h2 className="title">Últimas noticias</h2>
				</div>
				<ul className="gallery-blog">
					{blog.filter((items, idx) => idx < 5).map((blog, index) => (
						<li key={blog.id} className={`gallery-blog-item ${index === 0 ? 'featured-post' : ''}`}>
							<Link to={`/${blog.slug}-${blog.id}`}>
								<article className="blog-card">
									<div className="heade">
										<picture>
											<img src={blog.featuredImage} alt={blog.featuredImageAltText} />
										</picture>
									</div>
									<div className="body">
										<h3>{blog.name}</h3>
										<div className="post-summary" dangerouslySetInnerHTML={{__html: blog.postSummary}} />
										<div className="author">
											<div className="info-author">
												<img className="avatar" src="/logo_alz_sports.png" alt="Avatar del autor" />
												{/* <span>{blog.authorName}</span> */}
												<span>ALZ Sports</span>
											</div>
											<span className="divider">•</span>
											<span className="post-date">{Moment(blog.publishDate).format("DD MMM YYYY")}</span>
										</div>
									</div>
								</article>
							</Link>
						</li>
					))}
				</ul>
			</section>
		</>
	);
};

export default BlogHome;