import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Moment from 'moment';
import 'moment/locale/es';
import { getFixturesResults } from '../../../infrastructure/api/index';
import './FixturesResults.scss';


const FixturesResults = () => {
    const location = useLocation()
    const league_id = location.pathname.split("-", -1).slice(-1);
    const [leagueID, setLeagueID] = useState(Number(league_id[0]));
    const [results, setResults] = useState([]);
    const [filterDate, setFilterDate] = useState([]);
    // const [filterRound, setFilterRound] = useState([]);
    // const [finalsRounds, setFinalsRounds] = useState([]);
    const DATE_MATCH = [ ...new Set(filterDate) ];    

    useEffect(() => {
        setLeagueID(Number(league_id[0]));
    },[league_id])
    
    useEffect(() => {
        getFixturesResults(leagueID).then((resp) => {
            try {
                resp?.map(res => {
					// console.log(res.match_hometeam_name.indexOf('final') > -1);
					// setFinalsRounds(res.match_hometeam_name.includes('final'));
					setFilterDate(current => [...current, res.match_date])
					/* {setFilterRound(current => [...current, [res.match_round, res.match_date]])} */
					return null
				});
                setResults(resp);
            } catch (error) {
                console.log(error);
            }
        });
    }, [leagueID]);

    const finalScoreHomeTeam = (score) => {

        if (score.match_hometeam_extra_score !== "") {
            return (Number(score.match_hometeam_ft_score) + Number(score.match_hometeam_extra_score)) ;
        }else {
            return score.match_hometeam_ft_score;
        }
    }

    const finalScoreAwayTeam = (score) => {

        if (score.match_awayteam_extra_score !== "") {
            return (Number(score.match_awayteam_ft_score) + Number(score.match_awayteam_extra_score)) ;
        }else {
            return score.match_awayteam_ft_score;
        }
    }

    console.log('results', results);

	return (
        <section className="fixtures-results-section">
            <div className="header-title">
                <h2>Partidos</h2>
            </div>
            {DATE_MATCH.map((dateMatch, index) =>
                <div key={index + 1} className="card-result">
                    <div className="header-match">
                        <div className="result-date">
                            {/* <span>Fecha {item.match_round}</span> */}
                            <span>Fecha</span>
                            <span className="date-match">{Moment(dateMatch).format("MMM D, YYYY")}</span>
                        </div>
                    </div>

                    {results?.map((item) => (
                        dateMatch === item.match_date && (
                            <div key={item.match_id} className="body-match">
                                <div className="calendar-match">
                                    <span>{item.match_status === 'Finished' ? 'Finalizado' : ''}</span>
                                </div>
                                <div className="match-teams my-2">
                                    {item.cards.map((card) => (
                                        card === "red card" && (
                                        <span>
                                            {card.card}
                                        </span>
                                        )
                                    ))}
                                    <h2 className="title-content home-competitor-name">{item.match_hometeam_name}</h2>
                                    <img src={item.team_home_badge} className="flag" alt="" />
                                    <div className="score-match d-flex justify-content-center align-items-center">
                                        <span>{finalScoreHomeTeam(item)}</span>
                                        <span>-</span>
                                        <span>{finalScoreAwayTeam(item)}</span>
                                    </div>
                                    <img src={item.team_away_badge} className="flag" alt="" />
                                    <h2 className="title-content away-competitor-name">{item.match_awayteam_name}</h2>
                                </div>

								{item.match_hometeam_penalty_score && item.match_awayteam_penalty_score && 
									<div className="penalty-section">
										<span>Resultado {item.match_hometeam_penalty_score} - {item.match_awayteam_penalty_score} en penales</span>
									</div>
								}
                                <div className="calendar-match">
                                    <span className="date-match">{item.match_time}</span>
                                    <span>-</span> 
                                    <span>{item.match_stadium.split(" (", 1)}</span>
                                </div>
                            </div>
                        )
                    ))}
                </div>
            )}

        </section>
	);
};

export default FixturesResults;