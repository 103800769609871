import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import { getFixturesLive } from '../../../infrastructure/api';
import './MatchLive.scss';


const MatchLive = () => {
    const [propLive, setPropLive] = useState([]);

    useEffect(() => {

        const interval = setInterval(() => {
            getFixturesLive(28).then((resp) => {
                try {
                    if (resp.includes('error')){
                      setPropLive([]);
                    } else {
                      setPropLive(resp);
                    }
                } catch (error) {
                    console.log(error);
                }
            });
          }, 3000);
          return () => clearInterval(interval);

    }, []);

	return (
        <>
            {propLive.length !== 0 && (
                <section className="match-live-section">
                    <div className="header-title">
                        <h2>En vivo</h2>
                    </div>
                    <div className="card-result">
                        {propLive.map((item) => (
                            <div key={item.match_id} className="body-match">
                                <div className="match-live-time">{item.match_status === 'Finished' ? 'Finalizado' : <span>{item.match_status}<b className="second-line">'</b></span> }</div>
                                <div className="match-teams my-2">
                                    <h2>{item.match_hometeam_name}</h2>
                                    <img src={item.team_home_badge} className="flag" alt="" />
                                    <div className="d-flex justify-content-center align-items-center">
                                        <span>{item.match_hometeam_score}</span>
                                        <span>-</span>
                                        <span>{item.match_awayteam_score}</span>
                                    </div>
                                    <img src={item.team_away_badge} className="flag" alt="" />
                                    <h2>{item.match_awayteam_name}</h2>
                                </div>
                                <div className="calendar-match">
                                    <span className="date-match">{Moment(item.match_date).format("MMMM D")}</span>
                                    <span>-</span> 
                                    <span>{item.match_stadium.split(" (", 1)}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            )}
        </>
	);
};

export default MatchLive;