import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getFixturesStandings } from '../../../infrastructure/api/index';
import './FixturesStandingsGroups.scss';


const FixturesStandingsGroups = () => {
    const location = useLocation()
    const league_id = location.pathname.split("-", -1).slice(-1);
    const [leagueID, setLeagueID] = useState(Number(league_id[0]));
    const [standings, setStandings] = useState([]);
    const [leagueRound, setLeagueRound] = useState([]);
    const [filterGroup, setFilterGroup] = useState([]);
    const GROUP_MATCH = [ ...new Set(filterGroup) ];

    useEffect(() => {
        setLeagueID(Number(league_id[0]));
    },[league_id])

    useEffect(() => {
      getFixturesStandings(leagueID).then((resp) => {
            try {
                resp?.map(res => {
					setFilterGroup(current => [...current, res.league_round])
                    setLeagueRound(res.league_round);
					return null
				});
                setStandings(resp);
            } catch (error) {
                console.log(error);
            }
        });
    }, [leagueID]);

    console.log('standings gorups league', standings);
    console.log('leagueRound', leagueRound);

	return (
        (standings.length !== 0 && leagueID !== 152) && (
            <section className="fixtures-standings-groups-section">
                <div className="header-title">
                    <h2>Cuadrangulares</h2>
                </div>
                <div className="content-standings">
                    {GROUP_MATCH.filter(emptyG => {return emptyG !== null && emptyG !== ''}).map((round, index) => (
                        <div key={index + 1} className="card-result">
                            <div className="group-header">{round}</div>
                            <table className="table">
                                <tbody>
                                    <tr className="scrollable-table_table_header__332MD">
                                        <th className="scrollable-table_transparent_cell__1WPUn"></th>
                                        <th className="scrollable-table_group_title__3PBsT scrollable-table_first_cell__3BiNj"></th>
                                        <th className="scrollable-table_competitor_name__3NiKK"></th>
                                        <th className="scrollable-table_table_header_cell__13H2P scrollable-table_table_cell_border__NWba_" id="scroll-table-major-header">J</th>
                                        <th className="scrollable-table_table_header_cell__13H2P" id="scroll-table-major-header">Gol</th>
                                        <th className="scrollable-table_table_header_cell__13H2P" id="scroll-table-major-header">+/-</th>
                                        <th className="scrollable-table_table_header_cell__13H2P scrollable-table_table_text_bold_cell__S8zfb" id="scroll-table-major-header">PTS</th>
                                        <th className="scrollable-table_table_header_cell__13H2P scrollable-table_table_cell_border__NWba_" id="scroll-table-header">G</th>
                                        <th className="scrollable-table_table_header_cell__13H2P" id="scroll-table-header">E</th>
                                        <th className="scrollable-table_table_header_cell__13H2P" id="scroll-table-header">P</th>
                                    </tr>
                                    {standings?.map((item, index) => (
                                        (round === item.league_round && item.stage_name === 'Clausura - Quadrangular' ) && (
                                            <tr key={index + 1} className="standings-table-row">
                                                <td className="scrollable-table_transparent_cell__1WPUn"></td>
                                                <td className="scrollable-table_first_cell__3BiNj">
                                                    <a className="standings-widget_competitor_link__3N7jG" rel="" href="/">
                                                        <div className={`standings-widget_table_row_destination__20ciq ${(item.overall_league_position === '1' || item.overall_league_position === '2') ? 'clasificated' : ''}`}></div>
                                                        <div className="standings-widget_competitor_index__2F5QE">{item.overall_league_position}</div>
                                                        <img className="standings-widget_competitor_logo__2iYGH" src={item.team_badge}  alt="" />
                                                    </a>
                                                </td>
                                                <td className="standings-widget_competitor_name__NTu3D">
                                                    <a className="standings-widget_competitor_info__kU5Vu" rel="" href="/">
                                                        <div className="ellipsis_container__3t5Ei standings-widget_competitor_name_text__1xvY3"> <span>{item.team_name}</span></div>
                                                    </a>
                                                    <span className=""></span>
                                                </td>
                                                <td className="scrollable-table_table_text_cell__1N6uY scrollable-table_table_text_major_cell__2tzxx scrollable-table_table_cell_border__NWba_"><bdi>{item.overall_league_payed}</bdi></td>
                                                <td className="scrollable-table_table_text_cell__1N6uY scrollable-table_table_text_major_cell__2tzxx"><bdi>{item.overall_league_GF}:{item.overall_league_GA}</bdi></td>
                                                <td className="scrollable-table_table_text_cell__1N6uY scrollable-table_table_text_major_cell__2tzxx"><bdi>{item.overall_league_GF - item.overall_league_GA}</bdi></td>
                                                <td className="scrollable-table_table_text_cell__1N6uY scrollable-table_table_text_major_cell__2tzxx scrollable-table_table_text_bold_cell__S8zfb"><bdi>{item.overall_league_PTS}</bdi></td>
                                                <td className="scrollable-table_table_text_cell__1N6uY scrollable-table_table_cell_border__NWba_"><bdi>{item.overall_league_W}</bdi></td>
                                                <td className="scrollable-table_table_text_cell__1N6uY"><bdi>{item.overall_league_D}</bdi></td>
                                                <td className="scrollable-table_table_text_cell__1N6uY"><bdi>{item.overall_league_L}</bdi></td>
                                            </tr>
                                        )
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ))}
                    
                </div>
            </section>
        )
	);
};

export default FixturesStandingsGroups;