
export const getFullName = (user) => `${user?.first_name || ''} ${user?.last_name || ''}`;

export const getValueFromLocalStorage = (key) => typeof window !== 'undefined' && localStorage.getItem(key);

export const setValueToLocalStorage = (key, value) => typeof window !== 'undefined' && localStorage.setItem(key, value);

export const RemoveValueFromLocalStorage = (key) => typeof window !== 'undefined' && localStorage.removeItem(key);

export const setVauleFromSessionStorage = (key, value) => typeof window !== 'undefined' && sessionStorage.setItem(key, value);

export const getValueFromSessionStorage = (key) => typeof window !== 'undefined' && sessionStorage.getItem(key);

export const RemoveValueFromSessionStorage = (key) => typeof window !== 'undefined' && sessionStorage.removeItem(key);
