import { Route } from 'react-router-dom';
import AdminLayout from '../../../shared/presentation/layouts/AdminLayout';
import PostPage from '../presentation/pages/Post';
import { postRoute } from './routes';

const postRouter = {
	layout: <AdminLayout />,
	children: [
		{
		  path: postRoute(),
		  element: <PostPage />,
		  routeComponent: Route,
		}
	]
};

export default postRouter;
