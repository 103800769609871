import React from 'react';
import PropTypes from 'prop-types';

const LegalBlock = ({ currentUser }) => {
	return (
		<div className="row justify-content-between legal-block">
            <span className="copyright">
                Copyright © {new Date().getFullYear()} ALZ Sports
            </span>
            <div className="policies">
                <a href="/politica-de-tratamiento-de-datos" target="_blank" className="ml-1">
                Política de privacidad |
                </a>
                <a href="/terminos-y-condiciones" target="_blank" className="ml-1">
                Términos y condiciones
                </a>
            </div>
        </div>
	);
};

LegalBlock.propTypes = {
	currentUser: PropTypes.object,
};

export default LegalBlock;