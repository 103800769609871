import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// import BoardBody from '../components/BoardBody';
import BlogHome from '../components/BlogHome';
import { getBlogHubSpot } from '../../infrastructure/api';
import './Home.scss';

const HomePage = () => {

	useEffect(() => {
		getBlogHubSpot()
			.then((response) => {
				console.log('response', response);
			})
	},[])

	return (
		<>
			<Helmet prioritizeSeoTags>
                <title>{`Inicio | ALZ Sports`}</title>
                <meta name="description" content={'ALZ Sports es una plataforma de noticias, resultados y estadísticas de fútbol, desde el Mundial y la Champions League hasta ligas de segundo nivel.'}/>
                <meta property="og:title" content={`Inicio | ALZ Sports`}/>
            </Helmet>
			<div className="home-page">
				<div className="container" style={{maxWidth: "1288px"}}>
					<div className="home-container">
						<BlogHome />
					</div>
				</div>
			</div>
		</>
	);
};

export default HomePage;
