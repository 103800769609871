// import { AdminLayout, PrivateRoute } from '@vecindario/suite-dashboard-layout-lib';
import { Route } from 'react-router-dom';
import AdminLayout from '../../../shared/presentation/layouts/AdminLayout';
import Competition from '../presentation/pages/Competition';
import { competitionRoute } from './routes';

const competitionRouter = {
	layout: AdminLayout,
	children: [
		{
			path: competitionRoute(),
			element: <Competition />,
			routeComponent: Route,
		}
	]
};

export default competitionRouter;