import { Route } from 'react-router-dom';
import AdminLayout from '../../../shared/presentation/layouts/AdminLayout';
import HomePage from '../presentation/pages/Home';
import { homeRoute } from './routes';

const homeRouter = {
	layout: <AdminLayout />,
	// router: [
	// 	{
	// 		path: homeRoute,
	// 		page: HomePage,
	// 		routeComponent: Route,
	// 	},
	// ],
	children: [
		{
		  path: homeRoute,
		  element: <HomePage />,
		  routeComponent: Route,
		}
	]
};

export default homeRouter;
