import React from 'react';
// import { Helmet } from 'react-helmet';
import FixturesResults from '../components/FixturesResults';
import MatchLive from '../components/MatchLive';
import CupChampion from '../components/CupChampion';
import FixturesToday from '../components/FixturesToday';
import FixturesStandingsGroups from '../components/FixturesStandingsGroups';
import FixturesStandings from '../components/FixturesStandings';
import InfoLeagues from '../components/InfoLeagues';
import './League.scss';

const BoardPage = () => {
	return (
		<>
			{/* <Helmet title={'Vecindario Suite - Oportunidades de negocio - Tablero'} /> */}
            
            <InfoLeagues />

            <div className="content-league">
                <div className="container" style={{maxWidth: "1288px"}}>
                    <div className="row">
                        <div className="col-md-5">
                            <FixturesResults />
                        </div>
                        <div className="col-md-7">
                            <MatchLive />
                            <CupChampion />
                            <FixturesToday />
                            <FixturesStandingsGroups />
                            <FixturesStandings />
                        </div>
                    </div>
                </div>
            </div>
		</>
	);
};

export default BoardPage;
