import { urlGetBlogHubSpot } from './backendUrls';

export const getBlogHubSpot = () => {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Access-Control-Allow-Origin': '*',
			// 'Authorization': `Bearer ${process.env.REACT_APP_API_HUBSPOT}`,
			'Authorization': 'Bearer pat-na1-281f0f86-3ac9-4499-9dcf-a8444b578159',
			'Content-Type': 'application/json',
			'mode': 'no-cors'
		}
	};
	return fetch(urlGetBlogHubSpot, requestOptions).then((response) => response.json());
};
