import React from 'react';
import { PropTypes } from 'prop-types';
import Header from '../Header';
import Footer from '../Footer';
import './AdminLayout.scss'

const AdminLayout = ({ children }) => {
	return (
		<>
			<Header />
			<div>{children}</div>
			<Footer />
		</>
	)
};

AdminLayout.propTypes = {
	children: PropTypes.node,
};

AdminLayout.defaultProps = {
	children: null,
};

export default AdminLayout;
