import React from 'react';
import PropTypes from 'prop-types';

const BrandBlock = () => {
	return (
		<div className="col-lg-6">
            <div className="brand-block">
                <div className="content-img">
                    <img src="/logo_alz_sports.png" alt='' />
                </div>
                <p>ALZ Sports es una plataforma de noticias, resultados y estadísticas de fútbol, desde el Mundial y la Champions League hasta ligas de segundo nivel.</p>
            </div>
        </div>
	);
};

BrandBlock.propTypes = {
	currentUser: PropTypes.object,
};

export default BrandBlock;