// import { authHeader, handleResponse } from '../../../../shared/infrastructure/api/apiHandler';
import { urlGetFixturesResults, urlGetFixturesStandings, urlGetFixturesLive } from './backendUrls';

export const getFixturesResults = (league_id) => {
	const requestOptions = {
		method: 'GET',
		// headers: authHeader,
	};

	return fetch(urlGetFixturesResults(league_id), requestOptions).then((response) => response.json());
};

export const getFixturesStandings = (league_id) => {
	const requestOptions = {
		method: 'GET',
		// headers: authHeader,
	};

	return fetch(urlGetFixturesStandings(league_id), requestOptions).then((response) => response.json());
};

export const getFixturesLive = (league_id) => {
	const requestOptions = {
		method: 'GET',
		// headers: authHeader,
	};

	return fetch(urlGetFixturesLive(league_id), requestOptions).then((response) => response.json());
};
