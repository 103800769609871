import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { getValueFromLocalStorage } from '../../../application/helpers/common-functions';

const LEAGUES_MENU = [
    {comp: 
        {
            leagues: [
                    {league_id: "120"}, // Primera A - Colombia
                    // {league_id: "517"}, // Primera B - Colombia
                    // {league_id: "44"}, // Liga Profesional Argentina - Argentina
                    // {league_id: "99"}, // Serie A - Brasil
                    // {league_id: "235"}, // Liga MX - México
                    {league_id: "152"}, // Premier League - Inglaterra
                    // {league_id: "302"}, // La Liga - España
                    // {league_id: "207"}, // Serie A - Italia
                    // {league_id: "175"}, // Bundesliga - Alemania
                    // {league_id: "168"}, // Ligue 1 - Francia
                    // {league_id: "266"}, // Primeira Liga - Portugal
                    // {league_id: "244"}, // Eredivisie - Paises Bajos
            ],
            competitions: [
                    {league_id: "18"}, // CONMEBOL Libertadores - Sudamérica
                    // {league_id: "385"}, // CONMEBOL Sudamericana - Sudamérica
                    // {league_id: "3"}, // UEFA Champions League - Europa
                    // {league_id: "4"}, // UEFA Europa League - Europa
                    // {league_id: "5"}, // Concacaf Champions League - Norte América
                    {league_id: "28"}, // Copa Mundial de la FIFA - Qatar 2022
            ],
        },
    }
    
]

const SubMenu = ({ show, onMouseEnter, event }) => {
    const [menuLeagues, setMenuLeagues] = useState([]);
    const [leaguesData, setLeaguesData] = useState([]);
    const [pathRoute, setPathRoute] = useState('');
    const history = useNavigate();

    useEffect(() => {
        setMenuLeagues(JSON.parse(getValueFromLocalStorage('leagues')))
    }, []);

    useEffect(() => {
        if (event === 'leagues') {
            setLeaguesData([])
            setPathRoute('liga')
            menuLeagues.map((leagues, i) => (
                LEAGUES_MENU.map((typeMenu) => (
                    typeMenu.comp.leagues.map((menu) => (
                        menu.league_id === leagues.league_id && (
                            setLeaguesData(current => [...current, leagues])
                        )
                    ))
                ))
            ))
        }
        if (event === 'competitions') {
            setLeaguesData([])
            setPathRoute('competicion')
            menuLeagues.map((leagues, i) => (
                LEAGUES_MENU.map((typeMenu) => (
                    typeMenu.comp.competitions.map((menu) => (
                        menu.league_id === leagues.league_id && (
                            setLeaguesData(current => [...current, leagues])
                        )
                    ))
                ))
            ))
        }

    }, [menuLeagues, event]);

    const handleRoute = (a, item) => {
        a.preventDefault();

        const route = `/${pathRoute}/${item.league_name.replace(/\s/g, '-').toLowerCase()}-${item.league_id}`;
        history(route);
    }
    
	return (
        <nav className={`sub-nav ${show ? 'show' : 'closing'}`} onMouseEnter={onMouseEnter}>
            <ul className="sub-menu">
                {/* {menuLeagues.filter((item, idx) => filterMenu(item.league_id) ).map((item, index) => ( */}
                {leaguesData.map((item, index) => (
                    index < 16 && (
                        <li key={item.league_id}>
                            <Link onClick={(a) => handleRoute(a, item)} className="nav-link">
                                <div className="logo">
                                    <img src={item.league_logo} alt='' />
                                </div>
                                <span>{item.league_name}</span>
                            </Link>
                        </li>
                    )
                ))}
            </ul>
        </nav>
	);
};

SubMenu.propTypes = {
	currentUser: PropTypes.object,
};

export default SubMenu;