import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Moment from 'moment';
import 'moment/locale/es';
import { getPostBlogHubSpot } from '../../../infrastructure/api';
import './Post.scss';

const PostPage = () => {
    const location = useLocation()
    const post_id = location.pathname.split("-", -1).slice(-1);
    const [post, setPost] = useState([]);
    const [postID, setPostID] = useState(Number(post_id[0]));

    useEffect(() => {
        setPostID(Number(post_id[0]));
    },[post_id])

	useEffect(() => {
		getPostBlogHubSpot(postID)
			.then((data) => {
				console.log('post', data);
                setPost(data);
			})
	},[postID])

	return (
		<>
            <Helmet prioritizeSeoTags>
                <title>{`${post.name} | ALZ Sports`}</title>
                <meta name="description" content={post.metaDescription}/>
                {/* <link rel="notImportant" href="https://www.chipotle.com" />
                <meta name="whatever" value="notImportant" />
                <link rel="canonical" href="https://www.tacobell.com" /> */}
                <meta property="og:title" content={`${post.name} | ALZ Sports`}/>
                <meta property="og:description" content={post.metaDescription}/>
				<meta property="og:image" content={post.featuredImage}/>
            </Helmet>
            <div className="post-section">
                <div className="container" style={{maxWidth: "1288px"}}>
                    <div className="content-post">
                        <div className="header-post">
                            <div className="image-header">
                                <img className="img" src={post.featuredImage} alt={post.featuredImageAltText} />
                            </div>
                            <div className="content-header">
                                <h1 className="title"><span>{post.name}</span></h1>
                            </div>
                        </div>
                        <div className="body-post">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="author">
                                        {/* <span>{blog.authorName}</span> */}
                                        <div className="info-author">
                                            <img className="avatar" src="/logo_alz_sports.png" alt="Avatar del autor" />
                                            <span>ALZ Sports</span>
                                        </div>
                                        <span className="divider">•</span>
                                        <span className="post-date">{Moment(post.publishDate).format("DD MMM YYYY")}</span>
                                    </div>
                                    <div className="post-summary" dangerouslySetInnerHTML={{__html: post.postBody}} />
                                </div>
                                <div className="col-md-3">
                                    <div className="pauta">
                                        Paute aquí
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</>
	);
};

export default PostPage;
