import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getFixturesResults } from '../../../infrastructure/api';
import './CupChampion.scss';


const CupChampion = () => {
    const location = useLocation()
    const league_id = location.pathname.split("-", -1).slice(-1);
    const [leagueID, setLeagueID] = useState(league_id[0]);
    const [champion, setChampion] = useState([]);

    useEffect(() => {
        setLeagueID(league_id[0]);
    },[league_id])

    useEffect(() => {
        getFixturesResults(leagueID).then((resp) => {
            try {
                resp.filter(item => item.stage_name === 'Final').map(match => (
                    setChampion(match)
                ))
            } catch (error) {
                console.log(error);
            }
        });
    }, [leagueID]);

	return (
        <>
            {champion.length !== 0 && (
                <section className="cup-champion-section">
                    <div className="header-title">
                        <h2>Campeón</h2>
                    </div>
                    <div className="card-result">
                            <div key={champion.match_id} className="body-match">
                                <div className="match-teams my-2">
                                    <img src={champion.team_home_badge} className="flag" alt="" />
                                    <h2>{champion.match_hometeam_name}</h2>
                                </div>
                                <div className="congratulation">
                                    <span>🥇 ¡Felicitaciones! 🏆</span>
                                </div>
                            </div>
                    </div>
                </section>
            )}
        </>
	);
};

export default CupChampion;